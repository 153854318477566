import React from "react";
import { Table } from "semantic-ui-react";
import config from "../../config/main.config";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import { getImagesInFolder, getTokenFromLocalStorage } from "../../helper/util";
import dayjs from "dayjs"
import 'moment/locale/de'
import sharedConfig from "../../../shared/sharedConfig";


export function getAppointment(id) {
    return new Promise(res => fetch(config.BASE_URL + "appointments/appointment/" + id, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });
        } else {
            //ERROR
        }
    }).catch(err => {
        console.error(err);
    }))
}

export function getAllAppointments() {
    return new Promise(res => fetch(config.BASE_URL + "appointments/all", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });
        } else {
            //ERROR
        }
    }).catch(err => {
        console.error(err);
    }));
}

export function getAllCurrentAppointments() {
    return new Promise(res => fetch(config.BASE_URL + "appointments/all/current", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });
        } else {
            //ERROR
        }
    }).catch(err => {
        console.error(err);
    }));
}

export function addAppointment(appointment) {
    return new Promise(res => fetch(config.BASE_URL + "appointments/add", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({ appointment: appointment })
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });
        } else {
            //ERROR
        }
    }).catch(err => {
        console.error(err);
    }));
}

export function updateAppointment(appointment) {
    return new Promise(res => fetch(config.BASE_URL + "appointments/edit", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({ appointment: appointment })
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });
        } else {
            //ERROR
        }
    }).catch(err => {
        console.error(err);
    }));
}

export function deleteAppointment(id) {
    return new Promise(res => fetch(config.BASE_URL + "appointments/delete/" + id, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });
        } else {
            //ERROR
        }
    }).catch(err => {
        console.error(err);
    }));
}

export function showAllAppointmentsListView(appointments, setEditClicked, setIsNewAppointment, setAppointmentId, setAllAppointments) {
    return appointments.map((appointment, index) =>
        <Table.Row className={'cursor-pointer'}>
            <Table.Cell onClick={_ => {
                setAppointmentId(appointment.frontendId);
                setIsNewAppointment(false);
                setEditClicked(true);
            }}>
                {appointment.name}
            </Table.Cell>
            <Table.Cell onClick={_ => {
                setAppointmentId(appointment.frontendId);
                setIsNewAppointment(false);
                setEditClicked(true);
            }}>
                {dayjs(appointment.dateStart).format('dddd[, den] DD.MMMM YYYY [um ] HH:mm') + ' Uhr'}
            </Table.Cell>
            <Table.Cell onClick={_ => {
                setAppointmentId(appointment.frontendId);
                setIsNewAppointment(false);
                setEditClicked(true);
            }}>
                {dayjs(appointment.dateEnd).format('dddd[, den] DD.MMMM YYYY [um ] HH:mm') + ' Uhr'}
            </Table.Cell>
            <Table.Cell onClick={_ => {
                setAppointmentId(appointment.frontendId);
                setIsNewAppointment(false);
                setEditClicked(true);
            }}>
                {appointment.location}
            </Table.Cell>
            <Table.Cell onClick={_ => {
                setAppointmentId(appointment.frontendId);
                setIsNewAppointment(false);
                setEditClicked(true);
            }}>
                {appointment.contact}
            </Table.Cell>
            <Table.Cell onClick={_ => {
                setAppointmentId(appointment.frontendId);
                setIsNewAppointment(false);
                setEditClicked(true);
            }} className={appointment.published ? 'green-text' : 'red-text'}>
                {appointment.published === 0 ? "Nicht Veröffentlicht" : "Veröffentlicht"}
            </Table.Cell>
            <Table.Cell className={appointment.fullyBooked ? 'red-text' : 'green-text'} onClick={_ => {
                setAppointmentId(appointment.frontendId);
                setIsNewAppointment(false);
                setEditClicked(true);
            }}>
                {appointment.fullyBooked === 0 ? `Plätze frei` : "Ausgebucht"}
            </Table.Cell>
            <Table.Cell>
                <Button color={'blue'}
                    onClick={_ => {
                        addAppointment(appointment).then(_ => {
                            getAllAppointments().then(appointments_ => {
                                setAllAppointments(appointments_);
                            })
                        })
                    }}>Kopieren</Button>
            </Table.Cell>
            <Table.Cell>
                <Button color={'red'}
                    onClick={_ => {
                        deleteAppointment(appointment.id).then(_ => {
                            getAllAppointments().then(appointments_ => {
                                setAllAppointments(appointments_);
                            })
                        })
                    }}>Löschen</Button>
            </Table.Cell>
        </Table.Row>
    )
}

export function showImagesInFolder(images, activeFolder, setAppointment) {

    return (
        <div className={'column'}
            style={{ marginTop: "10px" }}>
            {
                images.map((image, index) =>
                    <picture>
                        <source
                            media={"(min-width: 1024px)"}
                            srcSet={config.BASE_URL_IMAGES + "desktop/" + image.name.slice(0, image.name.lastIndexOf('.') || image.name.length) + `.webp ${sharedConfig.IMAGE_SIZES.LARGE}w`
                                + "," + config.BASE_URL_IMAGES + "desktop/" + image.name} />
                        <source
                            media={"(min-width: 321px)"}
                            srcSet={config.BASE_URL_IMAGES + "tablet/" + image.name.slice(0, image.name.lastIndexOf('.') || image.name.length) + `.webp ${sharedConfig.IMAGE_SIZES.MEDIUM}w`
                                + "," + config.BASE_URL_IMAGES + "tablet/" + image.name} />
                        <source
                            media={"(max-width: 320px)"}
                            srcSet={config.BASE_URL_IMAGES + "mobile/" + image.name.slice(0, image.name.lastIndexOf('.') || image.name.length) + `.webp ${sharedConfig.IMAGE_SIZES.SMALL}w`
                                + "," + config.BASE_URL_IMAGES + "mobile/" + image.name} />
                        <img
                            className={'folder-image'}
                            onClick={() => setAppointment(prev => ({
                                ...prev,
                                thumbnail: image.name
                            }))}
                            src={config.BASE_URL_IMAGES + `userFolders/${activeFolder}/${image.name}`}
                            alt={''}
                        />
                    </picture>
                )
            }
        </div>
    )
}

export function showUserFolders(folders, setActiveFolder, setImages, activeFolder) {
    return folders.map((folder, index) =>
        <Button active={activeFolder === folder.name}
            icon={"folder"}
            className={"admin-button-primary"}
            content={folder.name}
            onClick={() => {
                setActiveFolder(folder.name);
                getImagesInFolder(folder.name).then(images =>
                    setImages(images)
                )
            }}
        />
    )
}

export function valueChanged(type, e, setAppointment) {
    let value = e.target.value;

    if (type === "blogLink") {
        let regex = /:?\/?\??#?\[?]?@?!?\$?&?'?\(?\)?\*?\+?,?;?=?\.?\u0020?/g;
        value = value.replace(regex, '');
        value = value.charAt(0).toLowerCase() + value.slice(1);
    }
    setAppointment(prevState => ({ ...prevState, [type]: value }));
}

export function mailtoBody(appointment, type) {
    if (type === "body") {
        return encodeURIComponent(`Liebes Calendula-Orgateam,
hiermit möchte ich mich für Ihre Veranstaltung "${appointment.name}" am ${dayjs(appointment.dateStart).format('dddd [, den] DD.MM.YYYY')} um ${appointment.dateStart ? appointment.dateStart.substring(appointment.dateStart.lastIndexOf("T") + 1, appointment.dateStart.length) : ""} Uhr anmelden.
Mit freundlichen Grüßen`);
    } else if (type === "subject") {
        return (`Anmeldung zur Veranstaltung ${appointment.name} am ${dayjs(appointment.dateStart).format('dd.MM.YYYY')}`)
    }
}